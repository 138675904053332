/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #084C61;
  color: white;
  height: 80px;
  padding: 0px 30px;
  position: relative;
  z-index: 10;
}

/* Dropdown toggle button for smaller screens */
.dropdown-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

/* Fonts */
@font-face {
  font-family: 'AnandaBlack';
  src: url('AnandaBlack.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.logo a {
  color: var(--theme-accent);
  font-size: 50px;
  font-family: 'AnandaBlack', sans-serif;
  text-decoration: none;
}
.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Desktop styles */
.menu-and-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 200px;
}

.menu .dropdown {
  position: relative;
  display: inline-block;
}

.menu .dropbtn {
  background-color: #084C61;
  color: #9EADC8;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.menu .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.menu .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menu .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.menu-and-toggle .menu .dropdown:hover .dropdown-content {
  display: block;
}

.menu .dropdown:hover .dropbtn {
  background-color: #212529;
}

/* Mobile styles */
@media (max-width: 1024px) {
  .dropdown-toggle {
    display: block;
    position: relative;
    z-index: 20; /* Ensure it's on top */
  }

  .menu-and-toggle {
    display: none;
  }

  .menu-and-toggle.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #084C61;
    position: absolute;
    top: 80px; /* Adjust based on navbar height */
    right: 0px; /* Align to the right below the hamburger icon */
    z-index: 10;
    width: auto; /* Adjust the width based on content */
    padding: 10px 0; /* Add padding for better spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    border: 1px solid #062C41;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: auto; /* Adjust the width based on content */
  }

  .menu .dropdown {
    width: auto; /* Adjust the width based on content */
    border-bottom: 1px solid #9EADC8;
    padding: 10px 20px; /* Add padding for better spacing */
  }

  .menu .dropbtn {
    text-align: left;
    background-color: transparent;
    color: #9EADC8;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    width: auto; /* Adjust the width based on content */
  }

  .menu .dropdown-content {
    position: relative;
    display: block;
    background-color: #084C61;
    width: auto; /* Adjust the width based on content */
  }

  .menu .dropdown-content a {
    color: #9EADC8;
    padding: 10px 20px;
    text-decoration: none;
    display: block;
  }

  .menu .dropdown-content a:hover {
    background-color: #212529;
  }

  .theme-toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto; /* Adjust the width based on content */
    padding: 10px 20px;
  }
}
  
.search-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

/* Desktop view styles */
.search-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: none; /* Remove max-width to let it expand */
  margin: 0 30px
}

/* Adjusted styling for the search input */
.search-input-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 600px;
}

.search-input {
  padding: 6px 60px 6px 10px; /* Right padding makes space for the button */
  width: 100%; /* Use full width of the search-container */
  border: 2px solid #084C61;
  border-radius: 30px;
  background-color: var(--background-color);
  color: var(--text-color);
  outline: none;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

/* Container for the icons */
.icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  right: 10px; /* Adjust as needed */
}

.search-input::placeholder {
  color: var(--theme-primary); /* Lighter color for placeholder text */
}

.search-input:focus {
  border-color: #9EADC8; /* Highlight color when focused */
}

/* Position the clear button before the search button */
.clear-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--theme-primary);
  font-size: 16px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--theme-primary);
  font-size: 16px;
}

.search-button:hover,
.clear-button:hover {
  color: var(--theme-accent);
}

/* Visibility classes */
.visible {
  display: block; /* Show the button */
}

.hidden {
  display: none; /* Hide the button */
}

/* Mobile view styles */
@media (max-width: 1024px) {
  .search-toggle {
    display: block;
  }

  .search-container {
    display: none;
  }

  .mobile-search-container {
    display: none; /* Initially hidden */
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    left: 0;
    background-color: #084C61;
    width: 100%; /* Full width of the container */
    position: absolute; /* Position it below the navbar */
    top: 80px; /* Adjust based on navbar height */
    z-index: 9; /* Ensure it's below the navbar */
    box-sizing: border-box;
  }

  .mobile-search-container.open {
    display: flex; /* Show when toggled */
  }

  .mobile-search-container .search-input-wrapper {
    position: relative;
    width: 100%;
  }

  .mobile-search-container .search-input {
    padding: 6px 50px 6px 10px;
    width: 100%; /* Use full width of the container */
    border: 2px solid #084C61;
    border-radius: 30px;
    background-color: var(--background-color);
    color: var(--text-color);
    outline: none;
  }

  .mobile-search-container .search-input::placeholder {
    color: var(--theme-primary); /* Lighter color for placeholder text */
  }

  .mobile-search-container .search-input:focus {
    border-color: #9EADC8; /* Highlight color when focused */
  }

  .mobile-search-container .icon-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    right: 10px; /* Adjust as needed */
  }

  .mobile-search-container .clear-button,
  .mobile-search-container .search-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--theme-primary);
    font-size: 16px;
  }


  .mobile-search-container .search-button:hover,
  .mobile-search-container .clear-button:hover {
    color: var(--theme-accent); /* Optional: change color on hover */
  }
}

  
/* Theme toggle navbar */
.theme-toggle-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px; /* Make sure the container has the same height as the switch */
}

.theme-icon {
  font-size: 24px; /* Adjust the size to match your toggle height */
  user-select: none; /* Prevent text selection */
}

/* The switch - the box around the slider */
.theme-toggle-switch {
  position: relative;
  display: inline-block; /* Use inline-block for proper alignment */
  width: 50px; /* Width of the switch */
  height: 24px; /* Height of the switch */
  margin: 0 10px; /* Add some space around the switch */
}

/* Hide default HTML checkbox */
.theme-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Slider background */
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px; /* Height of the slider circle */
  width: 20px; /* Width of the slider circle */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3; /* Color when checked */
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; /* Focus style */
}

input:checked + .slider:before {
  transform: translateX(26px); /* Slide animation */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
  