/* index.css or footer.css */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  background-color: #084C61;
  position: relative;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logoButton {
  background: none;
  border: none;
  color: var(--theme-accent);
  font-size: 50px;
  font-family: 'AnandaBlack', sans-serif;
  cursor: pointer;
  margin-top: -15px;
}

.footer-link {
  color: var(--theme-accent);
  text-decoration: none;
  font-size: 16px;
  font-family: var(--font-primary); /* Ensure it uses the primary font */
  margin-top: 10px; /* Adjust margin as needed */
}

.footer-link:hover {
  text-decoration: underline;
}
